// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Regular.ttf");
}

/** Ionic CSS Variables **/
:root {
    --ion-font-family: 'Poppins';

    --ion-text-color: var(--ion-color-very-dark);
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-toolbar-color: var(--ion-color-primary-contrast);

    /** primary **/
    --ion-color-primary: #073637;
    --ion-color-primary-rgb: 7, 54, 55;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #063030;
    --ion-color-primary-tint: #204a4b;

    /** secondary **/
    --ion-color-secondary: #56b9a3;
    --ion-color-secondary-rgb: 86, 185, 163;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #4ca38f;
    --ion-color-secondary-tint: #67c0ac;

    /** tertiary **/
    --ion-color-tertiary: #054a54;
    --ion-color-tertiary-rgb: 5, 74, 84;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #04414a;
    --ion-color-tertiary-tint: #1e5c65;

    /** success **/
    --ion-color-success: #56b9a3;
    --ion-color-success-rgb: 86, 185, 163;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #4ca38f;
    --ion-color-success-tint: #67c0ac;

    /** warning **/
    --ion-color-warning: #F5F749;
    --ion-color-warning-rgb: 245, 247, 73;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d8d940;
    --ion-color-warning-tint: #f6f85b;

    /** danger **/
    --ion-color-danger: #ce113a;
    --ion-color-danger-rgb: 206, 17, 58;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #b50f33;
    --ion-color-danger-tint: #d3294e;

    /** dark **/
    --ion-color-dark: #434344;
    --ion-color-dark-rgb: 67, 67, 68;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #3b3b3c;
    --ion-color-dark-tint: #565657;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** medium-light **/
    --ion-color-medium-light: #dfdfdf;
    --ion-color-medium-light-rgb: 223, 223, 223;
    --ion-color-medium-light-contrast: #000000;
    --ion-color-medium-light-contrast-rgb: 0, 0, 0;
    --ion-color-medium-light-shade: #c4c4c4;
    --ion-color-medium-light-tint: #e2e2e2;

    /** very-dark **/
    --ion-color-very-dark: #323232;
    --ion-color-very-dark-rgb: 42, 44, 43;
    --ion-color-very-dark-contrast: #ffffff;
    --ion-color-very-dark-contrast-rgb: 255, 255, 255;
    --ion-color-very-dark-shade: #252726;
    --ion-color-very-dark-tint: #3f4140;

    /** orange **/
    --ion-color-orange: #FF9800;
    --ion-color-orange-rgb: 255, 152, 0;
    --ion-color-orange-contrast: #000000;
    --ion-color-orange-contrast-rgb: 0, 0, 0;
    --ion-color-orange-shade: #e08600;
    --ion-color-orange-tint: #ffa21a;

    /** green **/
    --ion-color-green: #4CAF50;
    --ion-color-green-rgb: 76, 175, 80;
    --ion-color-green-contrast: #000000;
    --ion-color-green-contrast-rgb: 0, 0, 0;
    --ion-color-green-shade: #439a46;
    --ion-color-green-tint: #5eb762;

    /** blue **/
    --ion-color-blue: #2196F3;
    --ion-color-blue-rgb: 33, 150, 243;
    --ion-color-blue-contrast: #ffffff;
    --ion-color-blue-contrast-rgb: 255, 255, 255;
    --ion-color-blue-shade: #1d84d6;
    --ion-color-blue-tint: #37a1f4;

    /** dark-blue **/
    --ion-color-dark-blue: #1A2087;
    --ion-color-dark-blue-rgb: 26, 32, 135;
    --ion-color-dark-blue-contrast: #ffffff;
    --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
    --ion-color-dark-blue-shade: #171c77;
    --ion-color-dark-blue-tint: #313693;

    /** departure **/
    --ion-color-departure: #F9A620;
    --ion-color-departure-rgb: 249, 166, 32;
    --ion-color-departure-contrast: #ffffff;
    --ion-color-departure-contrast-rgb: 0, 0, 0;
    --ion-color-departure-shade: #db921c;
    --ion-color-departure-tint: #faaf36;

    /** return **/
    --ion-color-return: #74d035;
    --ion-color-return-rgb: 116, 208, 53;
    --ion-color-return-contrast: #ffffff;
    --ion-color-return-contrast-rgb: 0, 0, 0;
    --ion-color-return-shade: #66b72f;
    --ion-color-return-tint: #82d549;

    /** intermediate **/
    --ion-color-intermediate: #00AEC0;
    --ion-color-intermediate-rgb: 0, 174, 192;
    --ion-color-intermediate-contrast: #ffffff;
    --ion-color-intermediate-contrast-rgb: 255, 255, 255;
    --ion-color-intermediate-shade: #0099a9;
    --ion-color-intermediate-tint: #1ab6c6;

    /** yellow **/
    --ion-color-yellow: #F0FF17;
    --ion-color-yellow-rgb: 240, 255, 23;
    --ion-color-yellow-contrast: #000000;
    --ion-color-yellow-contrast-rgb: 0, 0, 0;
    --ion-color-yellow-shade: #d3e014;
    --ion-color-yellow-tint: #f2ff2e;

    /** red **/
    --ion-color-red: #FF2617;
    --ion-color-red-rgb: 255, 38, 23;
    --ion-color-red-contrast: #ffffff;
    --ion-color-red-contrast-rgb: 255, 255, 255;
    --ion-color-red-shade: #e02114;
    --ion-color-red-tint: #ff3c2e;
}

@media (prefers-color-scheme: light) {
    body {
        --ion-background-color: #FFF;
        --ion-background-color-rgb: 255, 255, 255;

        --ion-text-color: #121212;
        --ion-text-color-rgb: 18, 18, 18;

        --ion-color-step-50: #e9eaed;
        --ion-color-step-100: #dddee1;
        --ion-color-step-150: #d2d3d5;
        --ion-color-step-200: #c7c8ca;
        --ion-color-step-250: #bcbcbf;
        --ion-color-step-300: #b0b1b3;
        --ion-color-step-350: #a5a6a8;
        --ion-color-step-400: #9a9a9c;
        --ion-color-step-450: #8e8f91;
        --ion-color-step-500: #838485;
        --ion-color-step-550: #78787a;
        --ion-color-step-600: #6c6d6e;
        --ion-color-step-650: #616163;
        --ion-color-step-700: #565657;
        --ion-color-step-750: #4b4b4c;
        --ion-color-step-800: #3f3f40;
        --ion-color-step-850: #343435;
        --ion-color-step-900: #292929;
        --ion-color-step-950: #1d1d1e;

        --ion-border-color: var(--ion-color-step-350);
        --ion-toolbar-background: var(--ion-color-primary);

        --ion-item-background: var(--ion-background-color);
        --ion-tab-bar-background: var(--ion-background-color);

        --ion-card-background: var(--ion-background-color);
        --ion-card-color: var(--ion-text-color);

        --with-bottom-border-color: var(--ion-color-medium-light);
    }
}

@media (prefers-color-scheme: dark) {
    /*
     * Dark Colors
     * -------------------------------------------
     */

    body {
        /** primary **/
        --ion-color-primary: #56b9a3;
        --ion-color-primary-rgb: 86, 185, 163;
        --ion-color-primary-contrast: #252525;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #4ca38f;
        --ion-color-primary-tint: #67c0ac;

        /** secondary **/
        --ion-color-secondary: #0c5d5e;
        --ion-color-secondary-rgb: 12, 93, 94;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #0b5253;
        --ion-color-secondary-tint: #246d6e;

        /** tertiary **/
        --ion-color-tertiary: #076d7b;
        --ion-color-tertiary-rgb: 7, 109, 123;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #06606c;
        --ion-color-tertiary-tint: #207c88;

        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;

        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;

        --ion-color-light: #434344;
        --ion-color-light-rgb: 67, 67, 68;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #3b3b3c;
        --ion-color-light-tint: #565657;

        /** dark-blue **/
        --ion-color-dark-blue: #242dbe;
        --ion-color-dark-blue-rgb: 36, 45, 190;
        --ion-color-dark-blue-contrast: #ffffff;
        --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
        --ion-color-dark-blue-shade: #2028a7;
        --ion-color-dark-blue-tint: #3a42c5;

        --ion-border-color: var(--ion-color-step-150);
        --ion-toolbar-background: var(--ion-color-step-50);
        --ion-toolbar-segment-color: var(--ion-color-step-950);

        --ion-item-background: var(--ion-background-color);
        --ion-item-color: var(--ion-color-step-850);
        --ion-tab-bar-background: var(--ion-background-color);

        --ion-card-background: var(--ion-color-step-50);

        --with-bottom-border-color: var(--ion-color-light);
    }

    /*
     * iOS Dark Theme
     * -------------------------------------------
     */

    .ios body {
        --ion-background-color: #1e1e1e;
        --ion-background-color-rgb: 30, 30, 30;

        --ion-text-color: #c8c8c8;
        --ion-text-color-rgb: 200, 200, 200;

        --ion-color-step-50: #272727;
        --ion-color-step-100: #2f2f2f;
        --ion-color-step-150: #383838;
        --ion-color-step-200: #404040;
        --ion-color-step-250: #494949;
        --ion-color-step-300: #515151;
        --ion-color-step-350: #5a5a5a;
        --ion-color-step-400: #626262;
        --ion-color-step-450: #6b6b6b;
        --ion-color-step-500: #737373;
        --ion-color-step-550: #7c7c7c;
        --ion-color-step-600: #848484;
        --ion-color-step-650: #8d8d8d;
        --ion-color-step-700: #959595;
        --ion-color-step-750: #9e9e9e;
        --ion-color-step-800: #a6a6a6;
        --ion-color-step-850: #afafaf;
        --ion-color-step-900: #b7b7b7;
        --ion-color-step-950: #c0c0c0;
    }

    .ios ion-modal {
        --ion-background-color: var(--ion-color-step-100);
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }


    /*
     * Material Design Dark Theme
     * -------------------------------------------
     */

    .md body {
        --ion-background-color: #1e1e1e;
        --ion-background-color-rgb: 30, 30, 30;

        --ion-text-color: #c8c8c8;
        --ion-text-color-rgb: 200, 200, 200;

        --ion-color-step-50: #272727;
        --ion-color-step-100: #2f2f2f;
        --ion-color-step-150: #383838;
        --ion-color-step-200: #404040;
        --ion-color-step-250: #494949;
        --ion-color-step-300: #515151;
        --ion-color-step-350: #5a5a5a;
        --ion-color-step-400: #626262;
        --ion-color-step-450: #6b6b6b;
        --ion-color-step-500: #737373;
        --ion-color-step-550: #7c7c7c;
        --ion-color-step-600: #848484;
        --ion-color-step-650: #8d8d8d;
        --ion-color-step-700: #959595;
        --ion-color-step-750: #9e9e9e;
        --ion-color-step-800: #a6a6a6;
        --ion-color-step-850: #afafaf;
        --ion-color-step-900: #b7b7b7;
        --ion-color-step-950: #c0c0c0;
    }
}

.ion-color-very-dark {
    --ion-color-base: var(--ion-color-very-dark);
    --ion-color-base-rgb: var(--ion-color-very-dark-rgb);
    --ion-color-contrast: var(--ion-color-very-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-very-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-very-dark-shade);
    --ion-color-tint: var(--ion-color-very-dark-tint);
}

.ion-color-medium-light {
    --ion-color-base: var(--ion-color-medium-light);
    --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
    --ion-color-contrast: var(--ion-color-medium-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-medium-light-shade);
    --ion-color-tint: var(--ion-color-medium-light-tint);
}

.ion-color-orange {
    --ion-color-base: var(--ion-color-orange);
    --ion-color-base-rgb: var(--ion-color-orange-rgb);
    --ion-color-contrast: var(--ion-color-orange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
    --ion-color-shade: var(--ion-color-orange-shade);
    --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-blue {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-yellow {
    --ion-color-base: var(--ion-color-yellow);
    --ion-color-base-rgb: var(--ion-color-yellow-rgb);
    --ion-color-contrast: var(--ion-color-yellow-contrast);
    --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
    --ion-color-shade: var(--ion-color-yellow-shade);
    --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-red {
    --ion-color-base: var(--ion-color-red);
    --ion-color-base-rgb: var(--ion-color-red-rgb);
    --ion-color-contrast: var(--ion-color-red-contrast);
    --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
    --ion-color-shade: var(--ion-color-red-shade);
    --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-dark-blue {
    --ion-color-base: var(--ion-color-dark-blue);
    --ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
    --ion-color-contrast: var(--ion-color-dark-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-blue-shade);
    --ion-color-tint: var(--ion-color-dark-blue-tint);
}

.ion-color-departure {
    --ion-color-base: var(--ion-color-departure);
    --ion-color-base-rgb: var(--ion-color-departure-rgb);
    --ion-color-contrast: var(--ion-color-departure-contrast);
    --ion-color-contrast-rgb: var(--ion-color-departure-contrast-rgb);
    --ion-color-shade: var(--ion-color-departure-shade);
    --ion-color-tint: var(--ion-color-departure-tint);
}

.ion-color-return {
    --ion-color-base: var(--ion-color-return);
    --ion-color-base-rgb: var(--ion-color-return-rgb);
    --ion-color-contrast: var(--ion-color-return-contrast);
    --ion-color-contrast-rgb: var(--ion-color-return-contrast-rgb);
    --ion-color-shade: var(--ion-color-return-shade);
    --ion-color-tint: var(--ion-color-return-tint);
}

.ion-color-intermediate {
    --ion-color-base: var(--ion-color-intermediate);
    --ion-color-base-rgb: var(--ion-color-intermediate-rgb);
    --ion-color-contrast: var(--ion-color-intermediate-contrast);
    --ion-color-contrast-rgb: var(--ion-color-intermediate-contrast-rgb);
    --ion-color-shade: var(--ion-color-intermediate-shade);
    --ion-color-tint: var(--ion-color-intermediate-tint);
}
