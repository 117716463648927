ion-spinner {
    color: var(--ion-color-primary);
}

ion-datetime {
    color: var(--ion-color-dark);

    @media (prefers-color-scheme: light) {
        --background: white;
    }
}

ion-button {
    text-transform: unset;
}

h3 {
    font-style: italic;
    font-weight: bold;
    margin-top: 10px;
    color: var(--ion-color-primary);
}

.secondary-toolbar {
    --background: var(--ion-color-light);
    --color: var(--ion-color-light-contrast);

    ion-input {
        --color: var(--ion-color-light-contrast);
    }

    --ion-toolbar-background: var(--ion-color-primary-contrast);
    --ion-toolbar-color: var(--ion-color-primary);

    @media (prefers-color-scheme: dark) {
        --background: var(--ion-color-step-100);

        --ion-toolbar-background: var(--ion-color-primary-contrast);
        --ion-toolbar-color: var(--ion-color-primary);
    }
}

.sc-ion-back-button-ios-h {
    --color: var(--ion-color-primary-contrast);
}

ion-content {
    background: var(--ion-background-color, #fff);
}

ion-footer {
    background: var(--ion-background-color, #fff);

    > gyzmo-button {
        margin: 10px;
    }
}
