/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "~swiper/swiper-bundle.css";

@import './theme/theme';
@import './theme/specific';
@import './theme/android';
@import './theme/ios';

.vertical-center-div {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0%, -50%);
}

.vertical-center {
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
}

.invisible {
    visibility: hidden;
}

ion-menu-button {
    color: var(--ion-color-primary);
}

ion-alert {
    &.select-alert {
        --max-width: 90%;

        .alert-radio-group {
            max-height: 90%;
        }
    }

    ion-backdrop {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
    }
}

ion-modal {
    > .wrapper {
        margin-bottom: 10px;
    }
}

.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.8) !important;
}

ion-popover {
    --min-width: 310px;
}

.full-popover {
    --min-width: 90%;
}

ion-item {
    --background: transparent;
    --min-height: 35px;
}

.mandatory:after {
    content: "\00a0*";
    color: red;
}

h2 {
    margin-bottom: 5px;
}

@media (min-height: 600px) and (min-width: 768px) {
    .modal-wrapper {
        height: 75% !important;
        top: 15%;
        position: absolute;
        display: block;
    }
}

.alert-wrapper {
    --min-width: 320px;
}

pre {
    margin: 0;
    font-family: unset;
    white-space: pre-wrap;
}

//text-wrap dans les ion-select
.alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
    white-space: pre-line !important;
}

h1 {
    margin-top: 12px;
    margin-bottom: 8px;
}

.background-progressbar {
    color: var(--ion-color-very-dark) !important;
    background-color: var(--ion-color-medium-light) !important;
    border-top: solid thin var(--ion-color-primary-contrast);
}

.fill-progressbar {
    color: var(--ion-color-primary-contrast) !important;
    background-color: var(--ion-color-primary) !important;
    height: 13px;
}

ion-toast {
    height: auto;
    display: contents;
}

ion-toast::part(container) {
    display: block;
}

.deactivate-damage {
    color: var(--ion-color-danger) !important;
}

[hidden] {
    display: none !important;
}

ion-col {
    display: grid;
}

ionic-rating-component {
    display: inline-block;

    > div {
        display: inline-block;
    }
}

ion-textarea {
    .native-wrapper {
        border-bottom: solid thin var(--ion-color-medium);
    }
}

ion-input {
    &.ion-valid {
        --highlight-color: var(--ion-color-primary) !important;
    }

    .native-wrapper {
        border-bottom: solid thin var(--ion-color-medium);
    }

    &.ion-touched {
        &.ion-invalid {
            .native-wrapper {
                border-bottom: solid thin var(--ion-color-danger);
            }
        }

        &.ion-valid {
            .native-wrapper {
                border-bottom: solid thin var(--ion-color-success);
            }
        }
    }
}
