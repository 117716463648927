.ios {
    ion-content {
        ion-title {
            position: relative;
            height: auto;
            padding: 0 20px 0 20px;
        }
    }

    ion-card {
        margin: 5px;
    }

    ion-card-title {
        font-size: 16px;
    }

    ion-searchbar {
        padding: 0;
    }

    ion-footer {
        background: var(--ion-background-color, #fff);
    }

    ion-segment {
        --color: var(--ion-color-primary);
    }
}
